<template>
  <div>
    <v-app>
      <div
        role="status"
        aria-live="polite"
        class="visually-hidden"
        id="live-region"
      >
        {{ $t('timesheetUI.TimesheetsForLabel') }}
        {{ selectedDate.startOfWeekLong }}
        {{ selectedDate.year }} to {{ selectedDate.endOfWeekLong }}
        {{ selectedDate.year }}
      </div>
      <a href="#addJobButton" class="skip-link">{{
        $t('timesheetUI.TimesheetsSkipBar')
      }}</a>
      <TimeEntryDrawer />
      <v-main :style="`padding-right: ${VMainRightOffset}`">
        <v-container fluid fill-height class="pa-5 pb-0">
          <timesheet-entry-dialog></timesheet-entry-dialog>

          <VueAnnouncer />
          <timesheet-loader v-if="loading" />

          <div
            v-if="!initialLoad"
            class="flex-grow-1 height-100 d-flex flex-column"
          >
            <AlertOnBehalfOf />
            <!-- going to need to shift these two -->
            <!-- they are making the sticky header too short so need to be put into header & ts-main-container instead-->
            <div class="flex-grow-1">
              <TheHeader class="mb-4" />
              <VDivider role="presentation" dark />
              <AssignedJobsBanner />
              <NavigationHeader class="mb-8 mt-4" />
              <CalendarHeader class="mb-4" />
              <!-- <timesheet-header></timesheet-header> -->

              <div aria-live="polite" aria-atomic="true" class="sr-only">
                {{ formatDateToAnnounceCurrentYearAndMonth(calendar[0].date) }}
              </div>

              <div v-if="absenceModule">
                <absence-rows></absence-rows>
              </div>

              <div class="ts-main-container">
                <div v-if="timesheetJobs.length > 0">
                  <!-- <div v-if="checkForOldArchitectureDuplicates">
                    <timesheet-duplicate-alert></timesheet-duplicate-alert>
                  </div> -->

                  <TimesheetManagePreference
                    v-if="
                      doesAnyJobRequireLocation && isLocationActiveForThisWeek
                    "
                  />

                  <TimesheetJobGroups :openActions="{ openTimesheetDialog }" />
                </div>

                <div v-else-if="!hasSomeContract">
                  <v-alert
                    class="alert alert-danger mt-9"
                    style="top: 15px; position: relative"
                    outlined
                    role="alert"
                    asp-validation-summary="All"
                    type="error"
                  >
                    <div
                      class="alert alert-danger"
                      role="alert"
                      asp-validation-summary="All"
                    >
                      {{ $t('ErrorMessages.5011') }}
                    </div>
                  </v-alert>
                </div>
                <div v-else-if="!timesheetJobs.length">
                  <timesheet-no-results-found
                    v-if="
                      searchTimesheetJobs ||
                      globalSearchSelectedFilters.length > 0
                    "
                  ></timesheet-no-results-found>
                  <timesheet-empty v-else></timesheet-empty>
                </div>
              </div>
            </div>
            <div v-if="calendar.length > 0 && hasSomeContract">
              <timesheet-footer class="mt-16"></timesheet-footer>
            </div>
          </div>

          <timesheet-dialog-delete-error></timesheet-dialog-delete-error>
          <timesheet-dialog-submit-confirmation></timesheet-dialog-submit-confirmation>

          <timesheet-dialog-hide-job-error></timesheet-dialog-hide-job-error>
          <timesheet-dialog-edit-hidden-job-error></timesheet-dialog-edit-hidden-job-error>

          <div v-if="absenceBookingAllowed && absenceModule">
            <div v-if="!loadingAbsenceData">
              <absence-form-modify></absence-form-modify>
            </div>
          </div>

          <dialog-alert></dialog-alert>
          <dialog-alert-confirm-action></dialog-alert-confirm-action>
          <manage-preference-dialog></manage-preference-dialog>
        </v-container>
      </v-main>
      <AlertToast ref="alertToast" />
    </v-app>
  </div>
</template>

<script>
import TheHeader from './components/timesheet-header'
import NavigationHeader from './components/navigation-header'
import CalendarHeader from './components/calendar-header'
import TimesheetEmpty from './components/TimesheetEmpty.vue'
import TimesheetLoader from './components/TimesheetLoader.vue'
import TimesheetEntryDialog from './components/TimesheetEntryDialog.vue'
import TimesheetDialogDeleteError from './components/TimesheetDialogDeleteError.vue'
import TimesheetDialogSubmitConfirmation from './components/TimesheetDialogSubmitConfirmation.vue'
// import TimesheetDuplicateAlert from './components/TimesheetDuplicateAlert.vue'
// import TimesheetDialogSelfConsent from './components/TimesheetDialogSelfConsent.vue'
import TimesheetDialogHideJobError from './components/TimesheetDialogHideJobError.vue'
import TimesheetDialogEditHiddenJobError from './components/TimesheetDialogEditHiddenJobError.vue'
import TimesheetJobGroups from './components/TimesheetJobGroups.vue'
import TimeEntryDrawer from './components/time-entry-drawer'
import TimesheetManagePreference from './components/TimesheetManagePreference.vue'

import AbsenceFormModify from './components/Absence/AbsenceFormModify.vue'
import AbsenceRows from './components/Absence/AbsenceRows.vue'
import DialogAlert from '@Components/dialogAlert/DialogAlert.vue'
import DialogAlertConfirmAction from '@Components/dialogAlert/DialogAlertConfirmAction.vue'
import ManagePreferenceDialog from './components/ManagePreferenceDialog.vue'
import TimesheetNoResultsFound from './components/TimesheetNoResultsFound.vue'

import AssignedJobsBanner from './components/assigned-jobs-banner'

import { clientMicroServiceGet } from '@apis/clientService'
import { EventBus } from '@Shared/EventBus'
import {
  formatDateFrontend,
  percisionRound,
  getTimesheetsDuplicate,
  getTimesheets,
} from '@Shared/filters'
import { uniqBy } from 'lodash'
import moment from 'moment'
import i18n from '@Shared/i18n'
import languageHandler from '@Shared/languageHandler'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import AlertOnBehalfOf from './components/on-behalf-of/OnBehalfOfAlert.vue'
import TimesheetFooter from './components/timesheet-footer'
import AlertToast from './components/alert-toast/AlertToast.vue'
import { cloneDeep } from 'lodash'

let tabIndexTotal = 5

export default {
  name: 'Timesheets',
  components: {
    TimesheetFooter,
    TheHeader,
    NavigationHeader,
    CalendarHeader,
    TimesheetEmpty,
    TimesheetLoader,
    DialogAlert,
    DialogAlertConfirmAction,
    TimesheetEntryDialog,
    AbsenceFormModify,
    AbsenceRows,
    TimesheetDialogDeleteError,
    TimesheetDialogSubmitConfirmation,
    // TimesheetDuplicateAlert,
    // TimesheetDialogSelfConsent,
    TimesheetDialogHideJobError,
    TimesheetDialogEditHiddenJobError,
    TimesheetJobGroups,
    AlertOnBehalfOf,
    TimeEntryDrawer,
    AssignedJobsBanner,
    TimesheetManagePreference,
    ManagePreferenceDialog,
    AlertToast,
    TimesheetNoResultsFound,
  },
  data() {
    return {
      UsersActiveDaysInThisWeek: 5,
      TimesheetDisplayStatus: '',
      userJobs: [],
      submittingTimesheets: false,
      lastAddedJob: null,
      tooltipsActive: [],
      submitTimesheetDayOrWeek: () => {},
      shouldRedirectToMissingTimesheetDashboard: null,
      nav: true,
    }
  },
  watch: {
    calendar(newVal) {
      if (newVal.length) {
        this.tooltipsActive = new Array(this.calendar.length).fill(false)
      }
    },
    /**
     * timesheetJobs - Observes changes in the timesheetJobs list.
     *
     * When the list changes, this method identifies the job that's newly added
     * to the list and sets that job as the lastAddedJob.
     */
    // timesheetJobs(newVal, oldVal) {
    //     const lastAddedJob = newVal.find(job => !oldVal.some(oldJob => oldJob.job_ID === job.job_ID));

    //     if (lastAddedJob && newVal.length > oldVal.length) {
    //         this.lastAddedJob = lastAddedJob;
    //     }
    // },
    isTimeEntryDrawerOpen(val) {
      const el = document.getElementById(this.activeCell)

      if (!val && this.activeCell && el) {
        this.$nextTick(() => {
          el.focus()
        })
      }
    },
    loading() {
      setTimeout(() => {
        if (!this.loading) {
          const el = document.getElementById(this.activeCell)
          if (this.activeCell && el && !this.isTimeEntryDrawerOpen) {
            el.focus()
          }
        }
      }, 300)
    },
    shouldRedirectToMissingTimesheetDashboard(newValue) {
      if (newValue === null) return

      if (!newValue) {
        this.loadData('initialLoad').then(() => {
          this.$announcer.polite('Timesheets are loaded')
        })
        this.loadOnBehalfOfMappings()
      }
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'userData',
      'userContracts',
      'agencyData',
      'timesheets',
      'firstAvailableContract',
      'navigateToDate',
      'altairCode',
      'loading',
      'calendar',
      'timesheetSelectedJob',
      'showDeleteError',
      'initialLoad',
      'timesheetDialogVisibility',
      'absenceModule',
      'businessRole',
      'showSelfConsentSubmitDialog',
      'deDupTimesheetJobsOg',
      'showHideJobError',
      'timesheetVisibilityFilter',
      'searchTimesheetJobs',
      'globalSearchSelectedFilters',
      'timesheetCellDetails',
      'activeCell',
      'isTimeEntryDrawerOpen',
    ]),
    ...mapGetters('storeTimesheets', [
      'selectedDate',
      'hoursTotalPerDay',
      'hasSomeContract',
      'timesheetJobs',
      'isHiddenJobsScreen',
      'doesAnyJobRequireLocation',
      'VMainRightOffset',
      'isLocationActiveForThisWeek',
      'currentDayJobFeatures',
    ]),
    ...mapState('storeAbsenceTracker', [
      'absenceData',
      'loadingAbsenceData',
      'absenceBookingAllowed',
    ]),

    hoursTotal() {
      let hoursTotal = this.hoursTotalPerDay
        .map((item) => item.hours)
        .reduce((a, b) => a + b, 0)
      let percentTotal =
        this.hoursTotalPerDay
          .map((item) => item.percent)
          .reduce((a, b) => a + b, 0) / 5

      return {
        hours: percisionRound(hoursTotal, 0.01),
        percent: percisionRound(percentTotal, 0.01),
      }
    },
    weekDaysList: function weekDaysList() {
      if (this.dayList != null && this.dayList.length > 0) {
        var daysInWeek = window._.filter(this.dayList, function (item) {
          return item.isWeekend === false
        })

        return daysInWeek
      } else {
        return []
      }
    },
    checkForOldArchitectureDuplicates() {
      const ignoreClzTasks = this.timesheetJobs.filter((a) => !a.isClarizenTask)
      const clzTasks = this.timesheetJobs.filter((a) => a.isClarizenTask)
      return (
        uniqBy(ignoreClzTasks, 'job_ID').length !== ignoreClzTasks.length ||
        uniqBy(clzTasks, (t) => [t.job_ID, t.clarizenTaskName].join())
          .length !== clzTasks.length
      )
    },
  },
  updated() {
    tabIndexTotal = 5
  },
  methods: {
    ...mapActions('storeTimesheets', [
      'loadData',
      'addNewTimesheet',
      'loadOnBehalfOfMappings',
    ]),
    ...mapMutations('storeTimesheets', [
      'setTimesheetExists',
      'setTimesheetSelectedJob',
      'setLoading',
      'setTimesheetSubmitConfirmation',
      'setPerformRemoveButtonFocus',
      'setDeDupTimesheetJobs',
      'setTimesheetEditJobError',
      'setTimesheetDetail',
      'setActiveCell',
    ]),
    showTooltip(index) {
      this.$set(this.tooltipsActive, index, true)
    },
    formatDateToAnnounceCurrentYearAndMonth(dateString) {
      const date = new Date(dateString)
      const year = date.getFullYear()
      const month = date.toLocaleString('default', { month: 'long' })
      return `Attention: You are currently viewing the timesheet for the month of ${month} in the year ${year}.`
    },
    special(day) {
      if (
        this.userData.altairCode === '10038440' &&
        (moment(day).isSame('2021-05-10') || moment(day).isSame('2021-05-11'))
      ) {
        return true
      }

      return false
    },
    getTabIndex() {
      return tabIndexTotal++
    },
    showTimesheetSearch() {
      EventBus.$emit('showTimesheetSearch')
    },
    scrollJobIntoView(rowIndex) {
      let yOffset = -200
      let element = document.querySelectorAll('.row.job-group')[rowIndex]
      let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

      window.scrollTo({ top: y, behavior: 'smooth' })
    },
    //TODO: rename after dialog is removed completely
    openTimesheetDialog(job, date, clarizenProjectId) {
      this.setActiveCell(`${(job.id + date.split('T')[0]).replaceAll('-', '')}`)

      if (moment(date).isAfter(moment().format('YYYY-MM-DD'))) {
        const dialogAlert = {
          dialogAlertType: 'error',
          dialogAlertTitle: i18n.t(
            'timesheetUI.ValidationMessages.GenericErrorTitle',
            window.CULTURE
          ),
          dialogAlertMessage: i18n.t(
            'timesheetUI.ValidationMessages.TimesheetEntryFutureNotAllowedMessage',
            window.CULTURE
          ),
        }

        EventBus.$emit('dialogAlertShow', dialogAlert)
        return
      }

      this.setTimesheetSelectedJob({ job, date, openDrawer: true })

      if (
        (job.isClarizenAssignedProject && job.isClarizenTask) ||
        job.selfAssignmentWithManageTasking
      ) {
        if (
          getTimesheetsDuplicate(
            this.timesheets,
            clarizenProjectId,
            date,
            true,
            job.isClarizenTask,
            job.clarizenTaskExternalId,
            job.task,
            this.currentDayJobFeatures(job, date).isHistoricalJob
          ).length > 0
        ) {
          const existingDetails = getTimesheetsDuplicate(
            this.timesheets,
            clarizenProjectId,
            date,
            true,
            job.isClarizenTask,
            job.clarizenTaskExternalId,
            job.task,
            this.currentDayJobFeatures(job, date).isHistoricalJob
          )

          if (existingDetails.length === 1) {
            this.updateTimesheetDetails(job, date, existingDetails, true)
          } else {
            this.setTimesheetExists(cloneDeep(existingDetails))
          }
        } else {
          this.updateTimesheetDetails(job, date)
        }
      } else {
        if (getTimesheets(this.timesheets, job.id, date).length > 0) {
          this.updateTimesheetDetails(
            job,
            date,
            getTimesheets(this.timesheets, job.id, date),
            true
          )
        } else {
          this.updateTimesheetDetails(job, date)
        }
      }
    },
    updateTimesheetDetails(
      job,
      date,
      existingDetails = [],
      isExisting = false
    ) {
      const details = cloneDeep(
        this.timesheetCellDetails?.get(`${job.id}--${date}`)
      )
      if (isExisting) {
        this.setTimesheetExists(cloneDeep(existingDetails))
        return
      }
      if (details) {
        this.setTimesheetDetail({
          openDrawer: true,
          data: details,
        })
      } else this.addNewTimesheet({ showDrawer: true })
    },
    //TODO: Ununsed function
    handleResticted(restrictionObj, timesheetJob, day) {
      const { startDate, endDate, errorType } = restrictionObj

      if (errorType === 'time-entry-restriction-error') {
        const dialogAlert = {
          dialogAlertType: 'error',
          dialogAlertTitle: i18n.t(
            `timesheetUI.ValidationMessages.TimesheetEntryRestriction`,
            window.CULTURE
          ),
          dialogAlertMessage: i18n.t(
            `timesheetUI.ValidationMessages.TimesheetEntryRestrictionMessage`,
            {
              startDate: formatDateFrontend(startDate),
              endDate: formatDateFrontend(endDate),
            }
          ),
        }

        EventBus.$emit('dialogAlertShow', dialogAlert)
      } else {
        this.openTimesheetDialog(
          timesheetJob,
          day.date,
          timesheetJob.isClarizenAssignedProject &&
            timesheetJob.clarizenProjectId
        )
      }
    },
    isPartTimeOrContractorEmployee() {
      let partTimeVerifier = this.calendar.some(
        (day) =>
          day.isPartTimeEmployee ||
          day.externalEmployeeIndicator ||
          day.employeeType == 'H'
      )
      if (
        this.agencyData.allowAllEmployeesSubmissionWithoutRestriction ||
        (this.agencyData.allowPartTimeContractorSubmissionWithoutRestriction &&
          partTimeVerifier)
      ) {
        return true
      }
    },
  },
  mounted() {
    languageHandler(this.$i18n)
  },
  created() {
    this.$announcer.assertive('Timesheets loading')

    EventBus.$on('dialogAlertShow', () => {
      this.setLoading(false)
    })

    const disableRedirect = window.getParameterByName('disableRedirect')
    const altairCode = window.getParameterByName('AltairCode')
    if (disableRedirect !== 'true') {
      clientMicroServiceGet(
        'Timesheet/Dashboard/ValidateDashboardForUser',
        {
          AltairCode: altairCode,
        },
        { timeout: 20000 }
      )
        .then((response) => {
          this.shouldRedirectToMissingTimesheetDashboard =
            response.data.value.isDashBoardActive
          if (response.data.value.isDashBoardActive) {
            const url = process.env.VUE_APP_TIMESHEET_DASHBOARD_URL
            window.location.href = url + window.location.search
          }
        })
        .catch((error) => {
          error.message
        })
    } else {
      this.shouldRedirectToMissingTimesheetDashboard = false
    }
  },
}
</script>

<style lang="scss" src="./styles/Timesheets.scss"></style>
