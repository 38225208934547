import {
  getEntitlements,
  getAbsences,
  getPublicHolidays,
  createAbsence,
  cancelAbsence,
  cancelApprovedAbsence as cancelVTApprovedAbsence,
} from '@/services/VTService'

import moment from 'moment'
import { uniqBy } from 'lodash'
import { calculateAbsencePercentage } from '@/utils/absence'

const state = () => {
  return {
    altairCode: window.getParameterByName('AltairCode'),
    userFullName: '',
    altairCodeOBO: null,
    managerAltairCode: window.getParameterByName('ManagerAltairCode'),
    clarizenUserId: window.settings?.clarizenUserId,
    managerClarizenUserId: window.settings?.ManagerClarizenUserId,
    absenceTypes: [],
    absenceDurationTypes: [],
    requiredHours: 0,
    requiredHoursHalf: 0,
    absenceData: [],
    timesheetStep: 0,
    calendar: [],
    disableSubmit: false,
    supervisorID: null,
    agencyCode: null,
    agencyCodeMultiple: false,
    loadingContractData: false,
    loadingAbsenceData: true,
    showCalendar: false,
    year: null,
    currentYear: parseFloat(moment().format('YYYY')),
    showAbsenceFormDialog: false,
    absenceCalendarDateRange: [],
    customModifyData: [],
    refreshModel: false,
    presetDurationType: '',
    absenceBookingAllowed: false,
    timestamp: 0,
    showModule: false,
    publicHolidays: [],
    agencyCodeOptions: [],
    agencyStartDate: null,

    // New Absence
    loading: false,
    currentTime: moment(),
    agencyData: {},

    generalError: null,
    absenceEntitlements: [],
    createdAbsences: [],
    absenceStatusMap: {
      autoApproved: 1,
      awaitingApproval: 2,
      approved: 3,
      rejected: 4,
      cancelled: 5,
      cancellationPending: 6,
      cancellationApproved: 7,
      cancellationRejected: 8,
    },
    absenceStatusNameMap: {
      1: 'Auto-approved',
      2: 'Awaiting Approval',
      3: 'Approved',
      4: 'Rejected',
      5: 'Cancelled',
      6: 'Cancellation Pending',
      7: 'Cancellation Approved',
      8: 'Cancellation Rejected',
    },
    expandAbsencePanel: 0,
    createdAbsencesFlat: [],
  }
}

const mutations = {
  setTimesheetDays(state, payload) {
    state.calendar = payload
  },

  setCustomModifyData(state, payload) {
    state.customModifyData = []
    state.customModifyData = payload
  },

  setPublicHolidays(state, payload) {
    state.publicHolidays = payload
  },

  // New Absence
  setAbsenceEntitlements(state, payload) {
    state.absenceEntitlements = payload
  },

  setCreatedAbsences(state, payload) {
    state.createdAbsencesFlat = payload

    const groupedAbsences = payload.reduce((result, item) => {
      const key = item.leaveType
      result[key] = result[key] || []
      result[key].push({ ...item })
      return result
    }, {})

    state.createdAbsences = groupedAbsences
  },

  clearAbsenceData(state) {
    state.absenceEntitlements = []
    state.createdAbsences = []
    state.publicHolidays = []
  },

  setExpandAbsencePanel(state, payload) {
    state.expandAbsencePanel = payload
  },
}

const getters = {
  getAbsenceTotalByDay: (state) => {
    const results = []

    state.createdAbsencesFlat.forEach((a) => {
      state.calendar.forEach((day) => {
        if (moment(day.date).day() == 6 || moment(day.date).day() == 0) {
          return 0
        }

        if (getters.canShowAbsenceOnTimesheet(a.status)) {
          const startDate = a.rangeId
            ? moment(a.rangeStartDate)
            : moment(a.absenceDate)
          const endDate = a.rangeId
            ? moment(a.rangeEndDate)
            : moment(a.absenceDate)

          if (moment(day.date).isSame(startDate)) {
            results.push({
              date: day.date,
              total: a.rangeId
                ? day.requiredTimesheetHours
                : a.leavesUtilizedInHours,
            })
          } else if (
            moment(day.date).isAfter(startDate) &&
            moment(day.date).isBefore(endDate)
          ) {
            results.push({
              date: day.date,
              total: a.rangeId
                ? day.requiredTimesheetHours
                : a.leavesUtilizedInHours,
            })
          } else if (moment(day.date).isSame(endDate)) {
            results.push({
              date: day.date,
              total: a.rangeId
                ? day.requiredTimesheetHours
                : a.leavesUtilizedInHours,
            })
          }
        }
      })
    })

    return results
  },

  canShowAbsenceOnTimesheet: (state) => {
    return (status) => {
      const allowedStatuses = [
        state.absenceStatusMap.approved,
        state.absenceStatusMap.awaitingApproval,
        state.absenceStatusMap.autoApproved,
        state.absenceStatusMap.cancellationPending,
        state.absenceStatusMap.cancellationRejected,
      ]
      return allowedStatuses.includes(status)
    }
  },

  getAbsenceTotalByAbsence(state, getters, _, rootGetters) {
    return (absences) => {
      const startOfWeek =
        rootGetters['storeTimesheets/selectedDate']?.startOfWeek
      const endOfWeek = rootGetters['storeTimesheets/selectedDate']?.endOfWeek
      let total = 0
      let invalidDaysOnWeekDaysCount = 0

      absences.forEach((a) => {
        if (getters.canShowAbsenceOnTimesheet(a.status)) {
          a.absenceDate = a.absenceDate.split('T')[0]
          const startDate = a.rangeId
            ? moment(a.rangeStartDate)
            : moment(a.absenceDate)
          const endDate = a.rangeId
            ? moment(a.rangeEndDate)
            : moment(a.absenceDate)

          if (!a.rangeId) {
            if (
              moment(a.absenceDate).day() == 6 ||
              moment(a.absenceDate).day() == 0
            ) {
              return 0
            }
            if (
              moment(a.absenceDate).isBetween(
                startOfWeek,
                endOfWeek,
                undefined,
                '[]'
              )
            ) {
              total = total + parseFloat(a.leavesUtilizedInHours)
            }
          }

          if (a.rangeId) {
            state.calendar.forEach((day) => {
              if (!day.isRequiredByUserContract && !day.isWeekend) {
                invalidDaysOnWeekDaysCount++
              }

              if (moment(day.date).day() == 6 || moment(day.date).day() == 0) {
                return 0
              }
              if (
                moment(day.date).isBetween(startDate, endDate, undefined, '[]')
              ) {
                total = total + day.requiredTimesheetHours
              }
            })
          }
        }
      })

      if (state.agencyData.isPercentageAgency) {
        const absenceHoursInPercent = calculateAbsencePercentage(
          total,
          state.agencyData.timesheetHoursPerDay
        )

        let requiredAbsenceDaysCount = 5

        // if (state.calendar[0].weekendEntryEnabled) {
        requiredAbsenceDaysCount =
          requiredAbsenceDaysCount - invalidDaysOnWeekDaysCount
        // }
        return absenceHoursInPercent / requiredAbsenceDaysCount
      } else {
        return total
      }
    }
  },
}

const actions = {
  loadAbsenseData({ state, commit, dispatch }, payload) {
    state.loadingAbsenceData = true
    state.year = payload.year
    state.refreshModel = false
    4
    if (payload.altairCodeOBO) {
      state.altairCodeOBO = payload.altairCodeOBO
    }

    if (
      state.altairCodeOBO &&
      state.altairCodeOBO !== payload.loginUserAltairCode
    ) {
      state.userFullName = payload.userFullName
    }
    commit('setTimesheetDays', payload.calendar)

    const agencies = payload.calendar
      .filter((a) => a.agencyCode)
      .map((a) => {
        const agencyGoLiveDate = window.AltairUserContract.agencyGoLiveDate
        return {
          agencyCode: a.agencyCode,
          date: a.agencyEndDate || '',
          startDate: moment(a.agencyStartDate).isBefore(agencyGoLiveDate)
            ? agencyGoLiveDate
            : a.agencyStartDate,
        }
      })

    if (agencies.length === 0) {
      state.loadingAbsenceData = false
      state.absenceBookingAllowed = false
    } else {
      if (agencies.every((a) => a.agencyCode === agencies[0].agencyCode)) {
        state.agencyCodeMultiple = false
        state.agencyCode = agencies[0].agencyCode
        state.agencyStartDate = agencies[0].startDate
      } else {
        state.agencyCodeMultiple = true
        state.agencyCodeOptions = uniqBy(agencies, 'agencyCode')
        state.agencyCode = state.agencyCodeOptions[0]?.agencyCode
      }

      dispatch('loadAgencyData')
      dispatch('fetchEntitlements')
      dispatch('fetchAbsences')
      dispatch('fetchPublicHolidays')
    }
  },

  // New Absence
  loadAgencyData({ state, rootGetters }) {
    state.agencyData = rootGetters['storeTimesheets/agencyData']
  },

  async fetchEntitlements({ state, commit }) {
    const params = {
      altairCode: state.altairCodeOBO || state.altairCode,
      agencyCode: state.agencyCode,
      year: state.year,
    }

    try {
      const entitlements = await getEntitlements(params)
      commit('setAbsenceEntitlements', entitlements.data.value.entitlements)
      state.loadingAbsenceData = false
      state.timestamp = Date.now()
      state.absenceBookingAllowed =
        state.agencyData?.absenceModuleEnabled ||
        state.agencyData.enableAbsenceModule
    } catch (error) {
      console.log(error)
    }
  },

  async fetchAbsences({ state, commit, rootGetters }) {
    const params = {
      altairCode: state.altairCodeOBO || state.altairCode,
      agencyCode: state.agencyCode,
      year: state.year,
    }

    const startOfWeek = rootGetters['storeTimesheets/selectedDate']?.startOfWeek
    const endOfWeek = rootGetters['storeTimesheets/selectedDate']?.endOfWeek

    const yearDifference = moment(endOfWeek).year() - moment(startOfWeek).year()

    try {
      params.fromDate = moment(startOfWeek).startOf('year').format('YYYY-MM-DD')
      params.toDate = moment(startOfWeek).endOf('year').format('YYYY-MM-DD')
      const absences = await getAbsences(params)

      let newYearAbsences = {}

      if (yearDifference > 0) {
        params.year = params.year + yearDifference
        params.fromDate = moment(endOfWeek).startOf('year').format('YYYY-MM-DD')
        params.toDate = moment(endOfWeek).endOf('year').format('YYYY-MM-DD')
        newYearAbsences = await getAbsences(params)
      }

      if (
        newYearAbsences.data &&
        newYearAbsences.data.value.absenceRequests.length
      ) {
        absences.data.value.absenceRequests = [
          ...absences.data.value.absenceRequests,
          ...newYearAbsences.data.value.absenceRequests,
        ]
      }
      const uniqueAbsences = absences.data.value.absenceRequests.filter(
        (item, index, self) =>
          item.rangeId === undefined ||
          self.findIndex((obj) => obj.rangeId === item.rangeId) === index
      )
      commit('setCreatedAbsences', uniqueAbsences)
    } catch (error) {
      console.log(error)
    }
  },

  async fetchPublicHolidays({ state, commit }) {
    const params = {
      altairCode: state.altairCodeOBO || state.altairCode,
      agencyCode: state.agencyCode,
      year: state.year,
      location: state.calendar[0].location,
    }

    try {
      const publicHolidays = await getPublicHolidays(params)
      commit('setPublicHolidays', publicHolidays.data.value)
    } catch (error) {
      console.log(error)
    }
  },

  async createNewAbsence({ state, dispatch }) {
    let durationTypeLocal = state.createAbsenceForm.durationType
    let leaveDateLocal = state.createAbsenceForm.leaveDate
    let leaveRangeDatesLocal = state.createAbsenceForm.leaveRangeDates

    if (
      state.createAbsenceForm.leaveRangeDates &&
      state.createAbsenceForm.leaveRangeDates?.[0] ===
        state.createAbsenceForm.leaveRangeDates?.[1]
    ) {
      durationTypeLocal = state.durationTypeMaster.one
      leaveDateLocal = state.createAbsenceForm.leaveRangeDates[0]
      leaveRangeDatesLocal = null
    }

    const params = {
      absenceBookingComments: state.createAbsenceForm.absenceBookingComments,
      absenceEntitlementId: state.createAbsenceForm.absenceEntitlementId,
      agencyCode: state.agencyCode,
      altairCode: state.altairCodeOBO || state.altairCode,
      durationType: durationTypeLocal,
      leaveDate: leaveDateLocal,
      leaveRangeDates: leaveRangeDatesLocal,
      location: null,
      partialHours: state.createAbsenceForm.partialHours,
    }

    try {
      await createAbsence(params)
      dispatch('fetchEntitlements')
      dispatch('fetchAbsences')
    } catch (error) {
      console.log(error)
    }
  },

  async cancelUnapprovedAbsence({ dispatch }, payload) {
    try {
      await cancelAbsence(payload)
      dispatch('fetchEntitlements')
      dispatch('fetchAbsences')
    } catch (error) {
      console.log(error)
    }
  },

  async cancelApprovedAbsence({ dispatch }, payload) {
    try {
      await cancelVTApprovedAbsence(payload)
      dispatch('fetchEntitlements')
      dispatch('fetchAbsences')
    } catch (error) {
      console.log(error)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
